<template>
  <el-dialog
    title="打回申请"
    width="30%"
    :visible.sync="visible"
    :before-close="handleClose">
    <el-input
      type="textarea"
      :autosize="{ minRows: 1, maxRows: 5}"
      :rows="2"
      maxlength="255"
      placeholder="请输入打回原因"
      v-model="data.reason"
      required>
    </el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmit" size="medium">打回</el-button>
      <el-button @click="handleClose" size="medium">取消</el-button>
    </span>

  </el-dialog>
</template>
<script>
import { teamReject } from '@/api'
export default {
  name: 'nopassDialog',
  props: {
  },
  data () {
    return {
      visible: false,
      data: {
        rid: '', //项目id
        reason: ''
      }
    }
  },
  computed: {

  },
  created () {
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    show (row) {
      this.visible = true
      this.data.rid = row.rid //项目id
    },
    handleClose () {
      this.visible = false
    },
    onSubmit () {
      console.log(this.data);
      if (this.data.reason === '') {
        this.$message({
          type: 'error',
          message: '请输入打回原因!'
        })
      } else {
        this.$confirm('是否确定将该申请打回？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          teamReject(this.data).then(res => {
            console.log(res)
            if (res.data.code === 200) {
              // this.$message({
              //   type: 'success',
              //   message: '已成功打回该申请!'
              // })
              // this.visible = false
              this.$alert('打回申请成功', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  this.visible = false
                  this.$emit('getList')
                }
              });
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
              this.visible = false
            }
          }).catch(err => {
            console.error(err)
          })
        })
      }
    }
  },
  components: {

  }
};
</script>
<style scoped lang="scss">
.body {
  flex: 1;
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 5px;
  overflow: auto;
}
.el-row {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 20px;
}
  .el-dialog{
    height: auto;
  }
</style>
