<template>
  <el-dialog
    title="查看信息"
    :visible.sync="visible"
    width="80%"
    :before-close="handleClose"
    class="check-wrap"
  >
    <div class="body">
      <ul class="report-team-info">
        <li>
          责任书：{{ approvalDetail.responsibility.length > 0 ? "" : "无" }}
        </li>
      </ul>
      <div
        class="image-preview"
        v-if="approvalDetail.responsibility.length > 0"
      >
        <el-image
          v-for="(item, index) in approvalDetail.responsibility"
          :key="index"
          style="width: 100px; height: 100px; margin-right: 10px;"
          :src="item"
          :preview-src-list="approvalDetail.responsibility"
          fit="cover "
        >
        </el-image>
      </div>

      <ul class="report-team-info">
        <li>汇款证明：{{ approvalDetail.prove.length > 0 ? "" : "无" }}</li>
      </ul>
      <div class="image-preview" v-if="approvalDetail.prove.length > 0">
        <el-image
          v-for="(item, index) in approvalDetail.prove"
          :key="index"
          style="width: 100px; height: 100px; margin-right: 10px;"
          :src="item"
          :preview-src-list="approvalDetail.prove"
          fit="cover"
        >
        </el-image>
      </div>

      <ul class="report-team-info info">
        <li><span>队伍名称：</span>{{ approvalDetail.team.teamName }}</li>
        <li><span>队伍人数：</span>{{ approvalDetail.peopleSum }}</li>
        <li><span>领队：</span>{{ approvalDetail.team.leaderName }}</li>
        <li>
          <span>领队联系电话：</span>{{ approvalDetail.team.leaderPhone }}
        </li>
        <div class="clear"></div>
      </ul>
      <ul class="report-team-info info">
        <li><span>教练：</span>{{ approvalDetail.team.coachName }}</li>
        <li><span>教练联系电话：</span>{{ approvalDetail.team.coachPhone }}</li>
        <li v-if="approvalDetail.team.coachName2">
          <span>教练：</span>{{ approvalDetail.team.coachName2 }}
        </li>
        <li v-if="approvalDetail.team.coachPhone2">
          <span>教练联系电话：</span>{{ approvalDetail.team.coachPhone2 }}
        </li>
        <div class="clear"></div>
      </ul>
      <ul
        class="report-team-info"
        v-if="
          approvalDetail.status === 3 &&
            approvalDetail.reason != null &&
            approvalDetail.reason != ''
        "
      >
        <li>打回原因：{{ approvalDetail.reason }}</li>
      </ul>

      <div class="report-item">
        <div class="report-item__header">个人项目</div>
        <el-table
          class="report-item__body"
          :data="person"
          max-height="400px"
          border
          fit
          style="width: 100%;"
          empty-text="用户没有填报"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="cardId"
            label="证件号码"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="birth"
            label="出生年月"
            width="110"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="60"></el-table-column>
          <el-table-column
            prop="group"
            label="组别"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="itemName"
            label="项目名称"
            min-width="280"
          ></el-table-column>
          <el-table-column prop="cost" label="费用"> </el-table-column>
        </el-table>
        <div class="cost-box">合计：{{ personalCost }} 元</div>
      </div>

      <div class="report-item">
        <div class="report-item__header">对练项目</div>
        <el-table
          class="report-item__body"
          :data="duel"
          max-height="400px"
          border
          fit
          style="width: 100%;"
          empty-text="用户未填报项目"
        >
          <el-table-column
            prop="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="cardId"
            label="证件号码"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="birth"
            label="出生年月"
            width="110"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="60"></el-table-column>
          <!-- <el-table-column
            prop="group"
            label="所在组别"
            width="120"
          ></el-table-column> -->
          <el-table-column
            prop="itemName"
            label="项目名称"
            min-width="280"
          ></el-table-column>
        </el-table>
        <div class="cost-box">合计：{{ duelCost }} 元</div>
      </div>

      <div class="report-item">
        <div class="report-item__header">集体项目</div>
        <el-table
          class="report-item__body"
          :data="collective"
          max-height="400px"
          border
          fit
          style="width: 100%;"
          empty-text="用户没有填报"
        >
          <el-table-column
            prop="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="cardId"
            label="证件号码"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="birth"
            label="出生年月"
            width="110"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="60"></el-table-column>
          <el-table-column prop="teamGroup" label="组别" width="120"></el-table-column>
          <el-table-column
            prop="itemName"
            label="项目名称"
            min-width="280"
          ></el-table-column>
        </el-table>
        <div class="cost-box">合计：{{ collectiveCost }} 元</div>
      </div>

      <div class="report-item">
        <div class="report-item__header">太极推手项目</div>
        <el-table
          class="report-item__body"
          :data="kickboxing"
          max-height="400px"
          border
          fit
          style="width: 100%;"
          empty-text="用户没有填报"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="cardId"
            label="证件号码"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="birth"
            label="出生年月"
            width="110"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="55"></el-table-column>
          <!-- <el-table-column
            prop="height"
            label="身高"
            width="60"
          ></el-table-column> -->
          <el-table-column
            prop="weight"
            label="体重"
            width="60"
          ></el-table-column>
          <!-- <el-table-column
            prop="kickboxingGroup"
            label="组别"
          ></el-table-column> -->
          <!-- <el-table-column
            prop="learningCycle"
            label="学习周期"
            width="80"
          ></el-table-column> -->
          <el-table-column prop="tuishouType" label="类型" width="80"></el-table-column>
          <el-table-column prop="cost" label="费用"></el-table-column>
        </el-table>
        <div class="cost-box">合计：{{ kickboxingCost }} 元</div>
      </div>

      <!-- <div class="report-item">
        <el-table class="report-item__body" :data="aggregateCost" border fit style="width: 100%;">
          <el-table-column prop="item" label="项目" width="100"></el-table-column>
          <el-table-column prop="personal" label="个人项目" min-width="100"></el-table-column>
          <el-table-column prop="collective" label="集体项目" min-width="100"></el-table-column>
          <el-table-column prop="peopleSum" label="运动员人数" min-width="100"></el-table-column>
          <el-table-column prop="cost" label="合计（元）" width="360"></el-table-column>
        </el-table>
      </div> -->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" v-if="approvalDetail.status === 0" disabled
        >未提交</el-button
      >
      <el-button
        type="danger"
        v-if="approvalDetail.status === 1"
        @click="$emit('reject', row)"
        >打回</el-button
      >
      <el-button
        type="primary"
        v-if="approvalDetail.status === 1"
        @click="handleReview"
        >通过</el-button
      >

      <el-button type="success" v-if="approvalDetail.status === 2" disabled
        >已审核</el-button
      >
      <el-button type="danger" v-if="approvalDetail.status === 3" disabled
        >已打回</el-button
      >
      <el-button
        type="primary"
        :loading="download"
        v-if="approvalDetail.status === 2"
        @click="handleOutput"
        >导出</el-button
      >
      <!-- <el-button type="primary" v-if="data.status === 0" @click="handleReview">审核通过</el-button>
      <el-button type="success" v-else-if="data.status === 1" @click="visible = false">已审批</el-button>
      <el-button type="info" v-else  @click="visible = false">未提交</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import { teamPass, getAdminTeam, outputInfoAdmin } from "@/api";
export default {
  name: "checkDialog",
  props: {},
  data() {
    return {
      download: false, // 下载加载动画
      visible: false,
      approvalDetail: {
        uid: "", //用户id
        rid: "", //项目id
        //'count': 0, //总费用（这里的需求改了，只需要显示所有项目的总费用）
        status: 0,
        team: {},
        person: [],
        duel: [],
        collective: [],
        kickboxing: [],
        responsibility: [],
        prove: [],
        reason: "", // 打回原因
        peopleSum: 0 // 运动员人数
      },
      row: {}
    };
  },
  computed: {
    // responsibility () {
    //   let data = this.approvalDetail.responsibility.split(',')
    //   let file = []
    //   data.forEach(item => {
    //     file.push(item)
    //   })
    //   return file
    // },
    // prove () {
    //   let data = this.approvalDetail.prove.split(',')
    //   let file = []
    //   data.forEach(item => {
    //     file.push(item)
    //   })
    //   return file
    // },
    person() {
      let arr = [];
      this.approvalDetail.person.forEach(item => {
        if (item.boxing) {
          arr.push({
            ...item,
            itemName: `${item.boxing}${item.boxingRoutine ? "-" : ""}${
              item.boxingRoutine
            }`,
            cost: 120
          });
        }
        if (item.instrument) {
          arr.push({
            ...item,
            itemName: `${item.instrument}${item.instrumentRoutine ? "-" : ""}${
              item.instrumentRoutine
            }`,
            cost: 120
          });
        }
      });
      return arr;
    },
    // 对练项目
    duel() {
      let arr = [];
      this.approvalDetail.duel.forEach((item, index) => {
        item.contestants?.forEach((contestant, contestantsIndex) => {
          arr.push({
            index: `${index + 1}-${contestantsIndex + 1}`,
            ...contestant,
            itemType: item.itemType,
            itemRoutine: item.itemRoutine,
            itemName: `${item.item}${item.itemRoutine ? "-" : ""}${
              item.itemRoutine
            }`,
            cost: 120
          });
        });
      });
      return arr;
    },
    collective() {
      let arr = [];
      this.approvalDetail.collective.forEach((item, index) => {
        item.contestants?.forEach((contestant, contestantsIndex) => {
          arr.push({
            index: `${index + 1}-${contestantsIndex + 1}`,
            ...contestant,
            itemType: item.itemType,
            itemRoutine: item.itemRoutine,
            itemName: `${item.item}${item.itemRoutine ? "-" : ""}${
              item.itemRoutine
            }`,
            cost: 100
          });
        });
      });
      return arr;
    },
    kickboxing() {
      let arr = [];
      this.approvalDetail.kickboxing.forEach(item => {
        arr.push({
          ...item,
          cost: 120
        });
      });
      return arr;
    },

    personalCost() {
      let cost = 0;
      this.person.forEach(element => {
        cost += 120;
      });
      return cost;
    },
    duelCost() {
      let cost = 0;
      this.duel.forEach(element => {
        cost += 120;
      });
      return cost;
    },
    collectiveCost() {
      let cost = 0;
      this.collective.forEach(element => {
        cost += 100;
      });
      return cost;
    },
    kickboxingCost() {
      let cost = 0;
      this.kickboxing.forEach(element => {
        cost += 120;
      });
      return cost;
    },
    // 汇总费用
    aggregateCost() {
      return [
        {
          item: "费用",
          personal: this.personalCost,
          duel: this.duelCost,
          collective: this.collectiveCost,
          kickboxing: this.kickboxingCost,
          // cost: this.approvalDetail.peopleSum * 20, // 按人数收费
          cost:
            this.personalCost +
            this.duelCost +
            this.collectiveCost +
            this.kickboxingCost, // 按项目收费
          peopleSum: this.approvalDetail.peopleSum
        }
      ];
    }
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    show(row) {
      this.row = row;
      this.visible = true;
      // 替换
      getAdminTeam({ id: row.rid })
        .then(res => {
          console.log("res.data[0]", res.data[0]);
          this.approvalDetail.uid = res.data[0].uid; //用户id
          this.approvalDetail.rid = res.data[0].rid; //项目id
          //this.approvalDetail.count = res.data[0].count //总费用（这里的需求改了，只需要显示所有项目的总费用）
          this.approvalDetail.peopleSum = res.data[0].peopleSum; // 队伍人数
          this.approvalDetail.status = row.status;
          this.approvalDetail.team = res.data[0].team;
          this.approvalDetail.person = res.data[0].person;
          this.approvalDetail.duel = res.data[0].duel;
          this.approvalDetail.collective = res.data[0].collective;
          this.approvalDetail.kickboxing = res.data[0].kickboxing || [];
          this.approvalDetail.reason = res.data[0].nopassReason;

          let responsibility =
            res.data[0].responsibility && res.data[0].responsibility.length > 0
              ? res.data[0].responsibility.split(",")
              : [];
          let responsibilityarr = [];
          responsibility.forEach(item => {
            responsibilityarr.push(item);
          });
          this.approvalDetail.responsibility = responsibilityarr;

          let prove = res.data[0].prove ? res.data[0].prove.split(",") : [];
          let provearr = [];
          prove.forEach(item => {
            provearr.push(item);
          });
          this.approvalDetail.prove = provearr;
        })
        .catch(err => {
          console.error(err);
        });
    },
    hide() {
      this.visible = false;
    },
    handleClose() {
      this.visible = false;
      this.approvalDetail = {
        uid: "", //用户id
        rid: "", //项目id// 'count': 0, 总费用（这里的需求改了，只需要显示所有项目的总费用）
        status: "",
        team: {},
        person: [],
        duel: [],
        collective: [],
        kickboxing: [],
        responsibility: "",
        prove: ""
      };
    },
    handleReview(done) {
      this.$confirm("点击确定通过审核", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          teamPass(this.approvalDetail.rid, {
            id: this.approvalDetail.rid
          })
            .then(res => {
              if (res.data.code === 200) {
                this.$emit("get-list");
                this.$message({
                  type: "success",
                  message: "已成功通过该申请!"
                });
                this.visible = false;
              }
            })
            .catch(err => {
              console.error(err);
            });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // })
        });
    },
    handleOutput() {
      this.download = true;
      outputInfoAdmin(this.approvalDetail)
        .then(res => {
          console.log(res);
          if (res.status === 200) {
            this.download = false;
            let blob = new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
            let downloadElement = document.createElement("a");
            let href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = `${this.approvalDetail.team.teamName}报名详情.xlsx`; //下载后文件名：队伍名+报名详情
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
          }
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.download = false;
        });
    }
  },
  components: {}
};
</script>

<style scoped lang="scss">
.body {
  flex: 1;
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 5px;
  overflow: auto;
}
.report-team-info {
  margin-top: 15px;
}
.image-preview {
  padding: 10px;
}

.info {
  padding-top: 15px;
  border-top: 1px solid;
}

.info li {
  margin-right: 15px;
  margin-top: 8px;
  color: #409eff;
}

.info span {
  color: #000;
}
</style>
