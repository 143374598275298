<template>
  <div class="admin-review">
    <el-form :inline="true" size="small" class="admin-query" label-width="85px">
      <el-form-item label="队名：">
        <el-input
          v-model="query.teamName"
          clearable
          placeholder="请输入队伍名称"
        />
      </el-form-item>

      <el-form-item label="领队：">
        <el-input v-model="query.leaderName" placeholder="请输入领队姓名" />
      </el-form-item>

      <el-form-item label="领队电话：">
        <el-input v-model="query.leaderPhone" placeholder="请输入领队电话" />
      </el-form-item>

      <el-form-item label="状态：">
        <el-select
          v-model="query.status"
          clearable
          placeholder="状态"
          @change="onConfirm"
        >
          <el-option label="全部" value></el-option>
          <el-option label="未提交" :value="0"></el-option>
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="已审核" :value="2"></el-option>
          <el-option label="已打回" :value="3"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="账号：">
        <el-input v-model="query.username" placeholder="请输入账号" />
      </el-form-item>

      <el-form-item label="教练：">
        <el-input v-model="query.coachName" placeholder="请输入领队姓名" />
      </el-form-item>

      <el-form-item label="教练电话：">
        <el-input v-model="query.coachPhone" placeholder="请输入领队电话" />
      </el-form-item>

      <!-- 这是跨组别的比赛，一个队伍不是唯一组别，因此不要这个搜索条件 -->
      <!-- <el-form-item label="组别：">
        <el-select v-model="query.group" placeholder="组别">
          <el-option label="全部" value></el-option>
          <el-option label="青年组" :value ="1"></el-option>
          <el-option label="中年组" :value ="2"></el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item style="margin-left:29px">
        <el-button type="primary" @click="onConfirm">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
      empty-text="没有符合条件的数据"
      class="report-item__body"
      :data="teamList"
      border
      fit
      style="width: 100%;"
    >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column label="队伍名称" min-width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.teamName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="领队" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.leaderName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="领队电话" width="114">
        <template slot-scope="scope">
          <span>{{ scope.row.leaderPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="教练" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.coachName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="教练电话" width="114">
        <template slot-scope="scope">
          <span>{{ scope.row.coachPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="账号" width="130">
        <template slot-scope="scope">
          <span>{{ scope.row.username }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="70">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 2">已审核</span>
          <span v-else-if="scope.row.status === 1">待审核</span>
          <span v-else-if="scope.row.status === 3">已打回</span>
          <span v-else>未提交</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.submitTime }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleCheck(scope.row)" size="mini"
            >查看</el-button
          >
          <!-- <el-button v-if="$store.state.user.username === 'admin'" type="success" @click="handleUpdate(scope.row)" size="mini">修改</el-button> -->
          <el-button
            type="danger"
            :disabled="scope.row.status === 0 || scope.row.status === 3"
            @click="handleReject(scope.row)"
            size="mini"
            >打回</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="query.page"
      :page-size="query.limit"
      layout="total, prev, pager, next"
      :total="count"
      style="text-align: center; margin-top: 15px;"
    ></el-pagination>

    <ApprovalDetailModal
      ref="check"
      @get-list="getList"
      @reject="handleReject"
    />
    <NopassModal ref="nopass" @getList="getList" />
  </div>
</template>

<script>
import ApprovalDetailModal from "./components/ApprovalDetailModal.vue";
import NopassModal from "./components/NopassModal.vue";
import { getAllList } from "@/api";
import { getFullTime } from "@/utils/utils";
export default {
  components: {
    ApprovalDetailModal,
    NopassModal
  },
  props: {},
  data() {
    return {
      info: null,
      query: {
        teamName: "",
        leaderName: "",
        status: "",
        tel: "",
        username: "",

        page: 1,
        limit: 10
      },
      count: 0,
      teamList: []
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  mounted() {},
  watch: {},
  methods: {
    getList() {
      getAllList(this.query)
        .then(res => {
          res.data.list.forEach((element, index) => {
            if (element.submitTime) {
              res.data.list[index].submitTime = getFullTime(element.submitTime);
            }
            res.data.list[index].peopleSumCost = element.peopleSum * 20;
          });
          this.teamList = res.data.list;
          this.count = res.data.count;
        })
        .catch(err => {
          console.error(err);
        });
    },
    // 搜索
    onConfirm() {
      this.getList();
    },
    handleCheck(row) {
      // this.info = row
      // this.checkVisible = true
      this.$refs.check.show(row);
    },
    handleUpdate(row) {
      this.$router.push({ path: "/home", query: { id: row.uid } });
    },
    handleReject(row) {
      this.$refs.nopass.show(row);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.query.page = val;
      this.getList();
    }
  }
};
</script>

<style scoped lang="scss">
.admin-review {
  padding: 30px;
}
</style>
